export default {
  lightGrey: "#cfd8dc",
  grey: "#90a4ae",
  darkGrey: "#37474f",
  gold: "#fff176",
  red: "#e57373",
  darkRed: "#c62828",
  purple: "#7986cb",
  teal: "#4db6ac",
  green: "#66bb6a",
  lime: "#d4e157",
  orange: "#ffa726",
  deepRed: "#ef5350",
  pink: "#f48fb1",
};
